@import url('https://fonts.cdnfonts.com/css/euclid-circular-a');

.aboutBg{
    height: 100vh;

    width: 100%;
    background: linear-gradient(257deg, #151A30 0%, #211D4D 28%, #271630 70%, #0F1836 100%), linear-gradient(0deg, rgba(21, 26, 48, 0.40) 0%, rgba(21, 26, 48, 0.40) 100%);
    padding-top: 40px;
    border-radius: 0 0 32px 32px;


}
.imgNameContainer{
    position: relative;
    top: -138px;
    left: 24px;
}

.imgName1{
    font-family: 'Euclid Circular A', sans-serif;
    color: #C5CEE0;
    font-size: 60px;
    font-weight: 500;
    line-height: 64px;
}
.imgName2{
    font-family: 'Euclid Circular A', sans-serif;
    -webkit-text-stroke: 0.8px #fff;
    color: transparent;
    font-size: 60px;
    font-weight: 500;
    line-height: 64px;  
    padding-left: 95px;
}
.rightCon{
    max-height: auto;

}
.artistImg{
    max-width: 609px;
    height: min-content;
    min-height: min-content;
    border-radius : 16px;
    margin-left: 40px;
}

.title1{
    font-family: 'Euclid Circular A', sans-serif;
    color: #edf1f7;
    font-size: 48px;
    font-weight: 500;
    line-height: 64px;
}
.title2{
    font-family: 'Euclid Circular A', sans-serif;
    -webkit-text-stroke: 0.8px #c5cee0;
    color: transparent;
    font-size: 48px;
    font-weight: 500;
    line-height: 64px;
    margin-bottom: 24px;
}
.desc{
    color: white;
font-size: 20px;
    font-family: 'Euclid Circular A', sans-serif;
    font-weight: 400;
line-height: 28px;
margin-bottom: 24px;
}

.content{
    width: 58%;
}
.container{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
@media only screen and (max-width: 1500px){
    .artistImg{
       /* display: none; */
       /* margin-left: 0px; */
       /* margin-bottom: 40px; */
       width: 509px;
       /* margin-top: 40px; */

    }
    .title1{
        font-family: 'Euclid Circular A', sans-serif;
        color: #C5CEE0;
        font-size: 38px;
        font-weight: 500;
        margin-bottom: 16px;
        line-height: 40px;
    }
    .title2{
        font-family: 'Euclid Circular A', sans-serif;
        -webkit-text-stroke: 0.8px #fff;
        color: transparent;
        font-size: 38px;
        font-weight: 500;
        line-height: 40px;
        margin-bottom: 24px;
    }
    .desc{

        margin-bottom: 0;
    }
}
@media only screen and (max-width: 1349px){
    .container{
        flex-direction: column;
    }
    .content{
        width: 100%;
    }
    .artistImg{
       /* display: none; */
       margin-left: 0px;
       /* margin-bottom: 40px; */
       width: 609px;
       margin-top: 40px;
       margin-bottom: 40px;

    }
    .aboutBg{
        /* padding-bottom: 32px; */
        height: auto;
padding-top: 0px;
    }
    .imgName2{
        padding-left: 140px;
    }
    .imgNameContainer{
        top: -138px;
        left: -105px;
        /* margin-left: 50px; */
    }
    
}
@media only screen and (max-width: 850px){
    .artistImg{
       /* width: 400px; */
       width: 100%;

    }
}
@media only screen and (max-width: 750px){
.title1{
    font-size: 40px;
}
.title2{
    font-size: 40px;
}
    .artistImg{
        width: 100%;
       /* display: none; */
    }
    .imgName2{
        padding-left: 80px;
    }
    .imgNameContainer{
        top: -138px;
        left: 24px;
    }
    
}
@media only screen and (max-width: 639.5px){

    .aboutBg{
        border-radius: 0;
      }

    }
@media only screen and (max-width: 610px){
    .title1{
        /* font-size: 30px; */
        line-height: 40px;
    }
    .title2{
        /* font-size: 30px; */
        line-height: 40px;
    }
        .artistImg{
            /* width: 342px; */
           /* display: none; */
           border-radius: 8.6px;
        }
    
   
    }

    @media only screen and (max-width: 440px){
        .title1{
            /* font-size: 26px; */
            margin-bottom: 8px;
            /* line-height: 30px; */
        }
        .title2{
            /* line-height: 30px; */
            /* font-size: 26px; */
            margin-bottom: 32px;

        }
        .desc{
            font-size: 14px;
            line-height: 20px;

        }
            .artistImg{
                /* width: 302px; */
               /* display: none; */
            }
            .imgNameContainer{
                top: -138px;
                left: 12px;
            }
          
        }