@import url('https://fonts.cdnfonts.com/css/euclid-circular-a');


.button {
    font-family: 'Euclid Circular A', sans-serif;
    /* background-color: #151A30; */
    color: #fff;
    /* border: 1px solid #e32682; */
    font-size: 32px;
    font-weight: 500;
    /* border-radius: 30px; */
    /* padding: 8px 32px; */
    display: inline-block;
    margin-bottom: 32px;
}



/* @media only screen and (max-width: 1080px){
    .button{
        display: flex;
    font-family: 'Euclid Circular A', sans-serif;
    color: #fff;
    font-size: 24px;
    font-weight: 400;
    margin-bottom: 30px;
}
} */