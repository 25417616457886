.App {
    font-family: sans-serif;
    text-align: center;
  }
  .slideXs{
    display: none;
  }

  @media only screen and (max-width: 470px){
    .slideSm{
      display: none !important;
    }
    .slideXs{
      display: block;
    }
}