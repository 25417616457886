
.aboutBg{
    /* height: 100vh; */

    /* width: 100%; */
    /* background: linear-gradient(257deg, #151A30 0%, #211D4D 28%, #271630 70%, #0F1836 100%), linear-gradient(0deg, rgba(21, 26, 48, 0.40) 0%, rgba(21, 26, 48, 0.40) 100%); */
    /* padding-top: 40px; */
    border-radius: 0 0 40px 40px;


}


@media only screen and (max-width: 639.5px){

    .aboutBg{
        border-radius: 0;
      }

    }