@import url('https://fonts.cdnfonts.com/css/euclid-circular-a');
.container{
  /* background-color: #fff; */
  border-radius: 8px;
  height: 48px;
  padding-top: 4px;
  margin-right: 5px;
  /* display: flex;  */
  /* align-items: center; */
  /* justify-content: space-around; */
}
.langItemDrawer{
  position: relative;
  left: -6px;
}

.itemDark {
    font-family: 'Euclid Circular A', sans-serif;
    font-size: 16px;
  color: #151a30;
  /* background-color: aqua; */

}
.itemLight {
  font-family: 'Euclid Circular A', sans-serif;
  font-size: 16px;
color: #828282;
/* background-color: aqua; */

}
.itemNav {
  font-family: 'Euclid Circular A', sans-serif;
  font-size: 16px;
color: #fff;
/* background-color: aqua; */
/*  */
}
.language{
  width: min-content;
  position: absolute;
  top: 7.5px;
  left: 14px;
}

.languageLight{
  color: #151A30;
}
.languageNav{
  color: #fff;
}


.MuiOutlinedInput-notchedOutline {
  border-width: 1px !important;
  border-color: rgb(184, 0, 31) !important;
}

