
@import url('https://fonts.cdnfonts.com/css/euclid-circular-a');
.buyTicket {
    overflow: hidden;
    min-width: 159px;
    text-align: center;
    position: relative;
    display: inline-block;
    background-color: #151A30;
  color: #fff;
  border-radius: 8px; 
  border: none;
  padding: 8.2px 16px;
  font-size: 16px;
  font-weight: 400;
  font-family: 'Euclid Circular A', sans-serif;
  font-size: 20px;
  display: flex;
  justify-content: center;
  transition: all 0.5s;
}
.buyTicketIcon{
    transition: all 0.5s;

}
/* .buyTicket:hover {
    transition: all 0.5s;   
    background-color: #1C213C;
    color: #fff;
    
} */
.buttonContainer{
    display: flex;
    cursor: pointer;
}
.buyTicketIcon {
    background-color: #151A30;
}
.icon{
    filter: invert(100%) sepia(0%) saturate(2%) hue-rotate(266deg) brightness(104%) contrast(101%);
    transition: all 0.5s;

}
.buttonContainer:hover .buyTicketIcon {
    background-color:  #e32682;
}

.buttonContainer:hover .buyTicket {
    background-color:  #e32682;
    color: white;
}
