@import url('https://fonts.cdnfonts.com/css/euclid-circular-a');

.font {
    font-family: 'Euclid Circular A', sans-serif;
}
.title{
    font-size: 80px;
    font-weight: 600;
line-height: 84px;
color: white;
margin-bottom: 48px;
margin-right: 40px;
}
.text{
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    color: white;
}
.button{
    font-family: 'Euclid Circular A', sans-serif;
    color: white;
    font-size: 24px;
    font-weight: 500;
}

@media only screen and (max-width: 599px){
    .title{
        font-size: 48px;
margin-bottom: 30px;
line-height: 63px;
    }
    
}

