@import url('https://fonts.cdnfonts.com/css/euclid-circular-a');

.card {
    cursor: pointer;
    border: 0;
    box-shadow: 0;
    transition: all 0.5s;
    margin: 0 0 40px 0;
    width: 375px;

}

.image {
    transition: all 0.5s;
    border-radius: 24px;
    filter: blur(0px);
    width: 375px;
    height: 375px;

}
.imageContainer{
    /* width: 335.13px; */
    /* height: 321.09px; */
    /* background-color: #E32682; */
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 24px;
    max-height: 370px;
    position: relative;
    z-index: 555;
    /* background-color: #F7F9FC; */

}

.content {
    padding: 16px 24px;
    border: 1px solid #192038;
    border-radius: 24px;
    background-color: #101426;
    transition: all 0.5s;
    height: 103.6px;
    margin-top: 3px;
}

.ticket{
    padding: 10px 20px;
    color: #151A30;
font-size: 20px;
font-family: Euclid Circular A;
font-weight: 500;
/* line-height: 32px; */
/* min-width: 218px; */
/* max-width: 218px; */
text-align: center;
font-family: 'Euclid Circular A', sans-serif;
background-color: white;
border-radius: 8px;
height: min-content;
position: absolute;
/* top: 190.545px; */
/* left: 120px; */
z-index: 5;
visibility: hidden;
transition: all 0.1s;
filter: blur(10px);
white-space: nowrap;

}

.title {
    color: #fff;
    font-size: 24px;
    font-family: 'Euclid Circular A', sans-serif;
    font-weight: 700;
    line-height: 32px;
    /* margin: 0 0 12px 0; */
    transition: all 0.5s;
    white-space: nowrap;
}

.date {
    font-family: 'Euclid Circular A', sans-serif;
    color: #fff;
    font-size: 16px;
    font-family: Euclid Circular A;
    font-weight: 400;
    transition: all 0.5s;
    white-space: nowrap;
    line-height: 24px;
}
.hall {
    font-family: 'Euclid Circular A', sans-serif;
    color: #fff;
    font-size: 16px;
    font-family: Euclid Circular A;
    font-weight: 400;
    transition: all 0.5s;
    white-space: nowrap;
    line-height: 24px;


      white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    /* word-wrap: break-word; */
    -webkit-line-clamp: 1;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    line-height: 0em; /* Sets line height to 1.5 times text size */
    height: 24px; /* Sets the div height to 2x line-height (3 times text size) */
    width: 100%; /* Use whatever width you want */
    white-space: normal; /* Wrap lines of text */
    overflow: hidden; /* Hide text that goes beyond the boundaries of the div */
    text-overflow: ellipsis; /* Ellipses (cross-browser) */
    -o-text-overflow: ellipsis; /* Ellipses (cross-browser) */
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1; /* number of lines to show */
    line-height: 24px;        /* fallback */
    max-height: 24px;       /* fallback */
    /* background-color: #F7F9FC; */
}

.price {
    color: #E32682;
    font-size: 20px;
    font-family: 'Euclid Circular A', sans-serif;
    font-weight: 500;
    line-height: 28px;
    white-space: nowrap;
}
.ellipse{
    margin: 0 8px;
    transition: all 0.5s;
    filter: invert(100%) sepia(0%) saturate(2%) hue-rotate(266deg) brightness(104%) contrast(101%);

    width: 6px;
}


.card:hover .content {
    transition: all 0.5s;
    background-color: #f7f9fc;
}

.card:hover .date {
    color: #151A30;
}
.card:hover .hall {
    color: #151A30;
}

.card:hover .title {
    color: #151A30;
}

.card:hover .image {
    /* backdrop-filter: blur(10px); */
    /* filter: blur(4px); */
    -webkit-filter: brightness(60%);
    -webkit-transition: all 1s ease;
    -moz-transition: all 1s ease;
    -o-transition: all 1s ease;
    -ms-transition: all 1s ease;
    transition: all 1s ease;
}

.card:hover .ticket {
visibility: visible;
transition: all 0.1s;
filter: blur(0px);
}

.card:hover .ellipse {
    filter: grayscale(00%);
}


@media only screen and (max-width: 1463px){
   
    .card {
        margin: 0 0 40px 0;
   
    }
}
@media only screen and (max-width: 1077px){
    .card {
        margin: 0 0 15px 0;
   
    }
}  
@media only screen and (max-width: 1002px){
   
.card {
    width: 350px;

}
.content{
    margin-top: 0px;
}
.image {
    transition: all 0.5s;
    border-radius: 24px;
    filter: blur(0px);
    width: 350px;
    height: 350px;
}
}
/* @media only screen and (max-width: 502px){
   
    .card {
        width: 240px;
    
    }
    
    .image {
        transition: all 0.5s;
        border-radius: 24px;
        filter: blur(0px);
        width: 240px;
        height: 240px;
    }
    } */
    @media only screen and (max-width: 640px){
 
        .card {
            width: 320px;
        
        }
        
        .image {
            transition: all 0.5s;
            border-radius: 24px;
            filter: blur(0px);
            width: 320px;
            height: 320px;
        }
    }
    
@media only screen and (max-width: 502px){
    .ellipse,
    .hall{
        /* display: none; */
    }

    
}