
@import url('https://fonts.cdnfonts.com/css/euclid-circular-a');
.buyTicket {
    overflow: hidden;
    /* min-width: 159px; */
    text-align: center;
    position: relative;
    display: inline-block;
    /* background-color: #1C213C; */
  color: #222b45;
  border-radius: 8px; 
  border: none;
  padding-right: 12px;
  font-size: 16px;
  font-weight: 400;
  font-family: 'Euclid Circular A', sans-serif;
  font-size: 20px;
  display: flex;
  justify-content: center;
  transition: all 0.5s;
}
.buyTicketIcon{
    transition: all 0.5s;
}
/* .buyTicket:hover {
    transition: all 0.5s;   
    background-color: #1C213C;
    color: #fff;
    
} */
.buttonContainer{
    display: flex;
    cursor: pointer;
    align-items: center;
  border-radius: 8px; 
  padding: 8px 32px;
  transition:  all .3s ease-in-out;

  /* margin-left: 10px; */
background-color: #fff;

}
.buyTicketIcon {
    background-color: red;
}
.icon{
    transition:  all .3s ease-in-out;

    /* fill: red; */
    border-radius: 20px;
}
.iconColor{
    border-radius: 20px;
    transition:  all .3s ease-in-out;

    /* fill: red; */
    color: #fff;
    background-color: #222b45;
    padding: 5px;


}

.buttonContainer:hover .buyTicketIcon {

    background-color:  #e32682;
}

.buttonContainer:hover {
    background-color:  #e32682;
    transition: all 0.5s;
    color: #fff;
}
.buttonContainer:hover .buyTicket {
    color:  #fff;
}
.buttonContainer:hover .iconColor {
    background-color:  #fff;
    color:  #e32682;
}

@media only screen and (max-width: 410px){
    .icon{
        display: none;
    }
    .buttonContainer{
        display: flex;
        cursor: pointer;
        align-items: center;
      border-radius: 8px; 
      padding-left: 20px;
      padding-right: 20px;
      transition: all 0.5s;
      /* margin-left: 10px; */
    
    
    }
}