@import url('https://fonts.cdnfonts.com/css/euclid-circular-a');

.darkButton{
    padding: 8px 11px;
    color: #f7f9fc;
font-size: 16px;
font-family: Euclid Circular A;
font-weight: 400;
line-height: 24px;
font-family: 'Euclid Circular A', sans-serif;
cursor: pointer;
/* text-align: center; */
transition: all 0.5s;
margin: 0 5px;
white-space: nowrap;
}

.darkButton:hover{
    transition: all 0.5s;
    color: #e32682;
}


.lightButton{
    padding: 8px 16px;
    margin-top: 8px;
    color: #151A30;
font-size: 16px;
font-family: Euclid Circular A;
font-weight: 400;
line-height: 24px;
font-family: 'Euclid Circular A', sans-serif;
cursor: pointer;
/* text-align: center; */
white-space: nowrap;
transition: all 0.5s;
}

.lightButton:hover{
    transition: all 0.5s;
    color: #e32682;
}






@media only screen and (max-width: 1300px){
    .darkButton,
    .LightButton{
        margin: 0 0px;

        padding: 16px 11px;
    }
    
  
}