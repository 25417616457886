@import url('https://fonts.cdnfonts.com/css/euclid-circular-a');

.infoImg{
    width: 100% !important;
    border-radius: 32px !important;
}
.infoTitle{
    margin: 40px 0 38px 0 !important;
    color: #f7f9fc !important;
font-size: 34px !important;
font-family: Euclid Circular A !important;
font-weight: 500 !important;
font-family: 'Euclid Circular A', sans-serif !important;

}

.infoText{
    color: #c5cee0 !important;
font-size: 16px !important;
font-family: Euclid Circular A !important;
font-weight: 400 !important;
font-family: 'Euclid Circular A', sans-serif !important;
margin-bottom: 20px !important;

}
html,
body {
  scroll-behavior: smooth;
}
@media only screen and (max-width: 640px){
    .infoImg{
        border-radius: 16px !important;
    }
    .infoTitle{
    margin: 12px 0 !important;
font-size: 28px !important;
line-height: 36px !important; 


    }
}