
.cardsContainerHome{
    overflow-x: auto;
    flex-wrap: nowrap;
    display: flex;
    gap: 92.91px;

}
.cardsContainer{
    display: flex;
    /* justify-content: space-between; */
    flex-wrap: wrap;
    gap: 92.91px;

}
@media only screen and (max-width: 1636px){
    .cardsContainer{
        display: flex;
        /* justify-content: space-between; */
        flex-wrap: wrap;
        gap: 71.91px;
    
    }
    .cardsContainerHome{
        gap: 71.91px;

    }
    
}
@media only screen and (max-width: 1586px){
    .cardsContainer{
        display: flex;
        /* justify-content: space-between; */
        flex-wrap: wrap;
        gap: 51.91px;
    
    }
    .cardsContainerHome{
        gap: 51.91px;

    }
    
}
@media only screen and (max-width: 1536px){
    .cardsContainer{
        display: flex;
        /* justify-content: space-between; */
        flex-wrap: wrap;
        gap: 39.91px;
    
    }
    .cardsContainerHome{
        gap: 39.91px;

    }
    
}
@media only screen and (max-width: 1506px){
    .cardsContainer{
        display: flex;
        /* justify-content: space-between; */
        flex-wrap: wrap;
        gap: 35.91px;
    
    }
    .cardsContainerHome{
        gap: 35.91px;

    }
    
}
@media only screen and (max-width: 1496px){
    .cardsContainer{
        display: flex;
        /* justify-content: space-between; */
        flex-wrap: wrap;
        gap: 31.91px;
    
    }
    .cardsContainerHome{
        gap: 31.91px;
    }
    
}
@media only screen and (max-width: 1486px){
    .cardsContainer{
        display: flex;
    justify-content: space-evenly;

        /* justify-content: space-between; */
        flex-wrap: wrap;
        /* gap: 81.91px; */
    
    }

    
}

@media only screen and (max-width: 912px){
    .cardsContainer{
        gap: 10px;
    
    }
    .cardsContainerHome{
        gap: 10px;

    }
    
}
@media only screen and (max-width: 640px){
    .cardsContainer{
    /* justify-content: start; */

        /* justify-content: space-between; */
        flex-wrap: wrap;
        /* gap: 81.91px; */
    
    }
}
