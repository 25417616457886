@import url('https://fonts.cdnfonts.com/css/euclid-circular-a');

.aboutBg{
    height: 100vh;

    width: 100%;
    padding-top: 40px;
    /* background-color: aqua; */
    background: linear-gradient(257deg, #151A30 0%, #211D4D 28%, #271630 70%, #0F1836 100%), linear-gradient(0deg, rgba(21, 26, 48, 0.40) 0%, rgba(21, 26, 48, 0.40) 100%);
    border-radius: 0 0 40px 40px;

}
.aboutBgHome{
    /* margin: 137px 0; */
    background-color: #151a30;
    padding: 80px 56px;
    border-radius: 32px;
}
.imgNameContainer{
    position: relative;
    top: -138px;
    left: 24px;
}
.homeBg{
    
}

.rightCon{
    max-height: 499px;

}
.artistImgHome{
    border-radius: 16px;
    height: min-content;
    max-width: 547px;
    margin-left: 30px;
}
.artistImg{
    max-width: 490px;
    height: min-content;
    border-radius: 16px;
    min-height: min-content;
    margin-left: 40px;

}

.title1{
    font-family: 'Euclid Circular A', sans-serif;
    color: #E4E9F2;
    font-size: 48px;
    font-weight: 500;
    line-height: 64px;
    margin-bottom: 16px;

}

.desc{
    color: white;
font-size: 20px;
    font-family: 'Euclid Circular A', sans-serif;
    font-weight: 400;
line-height: 28px;
}

.content{
    width: 50%;
}
.container{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

@media only screen and (max-width: 639.5px){
    .aboutBgHome{
        border-radius: 0;
            padding: 25px 0px;
    }
    .aboutBg{
        border-radius: 0;
      }

    }
@media only screen and (max-width: 1349px){
    .aboutBg{
        height: auto;
    }
    .container{
        flex-direction: column;
    }
    .content{
        width: 100%;
    }
    .artistImg{
       /* display: none; */
       margin-left: 0px;
       margin-top: 40px;
    }
    .artistImgHome{
        /* display: none; */
        /* margin-left: 50px; */
        margin-top: 40px;
        margin-bottom: 40px;
        width: 100%;
        margin-left: 0;
     }
    
    .aboutBg{
        /* padding-bottom: 32px; */
        padding-top: 0px;
    }
    .imgName2{
        padding-left: 140px;
    }
    .imgNameContainer{
        top: -138px;
        left: -105px;
        /* margin-left: 50px; */
    }
    
}

@media only screen and (max-width: 750px){
.title1{
    font-size: 40px;
}
.title2{
    font-size: 40px;
}
    .artistImg{
        width: 342px;
       /* display: none; */
    }
 
    
}

@media only screen and (max-width: 610px){
    .title1{
        font-size: 30px;
        line-height: 40px;
    }
    .title2{
        font-size: 30px;
        line-height: 40px;
    }
        .artistImg{
            width: 342px;
           /* display: none; */
        }
    
      
    }

    @media only screen and (max-width: 440px){
       
            .artistImg{
                width: 302px;
               /* display: none; */
            }
        
          
        }