.selected{
    background-color: aqua;
    color: blue;
}

@media only screen and (min-width: 1280px){
    .items{
        margin-left: 180px;
    }
}
@media only screen and (min-width: 1169px) and (max-width: 1279px){
    .items{
        margin-left: 70px;
    }
}
@media only screen and (min-width: 1129px) and (max-width: 1168px){
    .items{
        margin-left: 40px;
    }
}
@media only screen and (min-width: 1000px) and (max-width: 1128px){
    .items{
        margin-left: 0px;
    }
}

.logoNavbar{
    width: 119.39px !important;
}

.navbar{
    background-color: #101426;
    border-radius: 0 0 24px 24px;
    width: 86.36667% !important;
    margin: auto !important;
    border-top: 0;
    padding: 30px 40px;
}

@media only screen and (max-width: 1080px){
    .navbar{
        border-radius: 0;
        width: 100% !important;
        border: 0;
    box-shadow: none;
    height: 72px !important;
    padding: 16px 16px;

    }
    .logoNavbar{
        width: 80px !important;
    }
    .appBar{
        padding: 0;
    height: 72px !important;

    }
}
