@import url('https://fonts.cdnfonts.com/css/euclid-circular-a');

.sliderImg{
    border-radius: 16px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    /* width: 100%; */
}
.sliderText{
    /* text-align: end; */
    position: absolute; 
    bottom: 25px;
font-family: 'Euclid Circular A', sans-serif;
color: #fff;
/* -webkit-text-stroke: 1px #151A30; */
font-size: 34px;
font-weight: 500;
line-height: 40px;
/* display: none; */
transition: all 1s;
color: transparent;

}


.sliderContainer:hover .sliderText{
    /* display: block; */
    /* padding-bottom: 20px;     */
-webkit-text-stroke: 1px #151A30;
color: #fff;

transition: all 1s;


}
/* className="md:w-32 text-center h-20 mx-auto flex justify-center items-center */
/* style={{width: '100%', textAlign: 'center', position: 'relative', top: '80px', backgroundColor: '#fff', opacity: '0.7', borderRadius: '22px 22px 0 0'}}  */

.slider{
    /* padding-top: 100px; */
    /* padding-bottom: 100px; */

}
.sliderContainer{
    margin-left: 50px;
    margin-right: 50px;
    box-shadow: none;

}
/* sm: 600, */
/* md: 1080, */
/* lg: 1200, */
@media only screen and (min-width: 1200px){
    .sliderContainer{
        margin-left: 50px;
        margin-right: 50px;
    }
}
@media only screen and (min-width: 1080px){
    .sliderContainer{
        margin-left: 50px;
        margin-right: 50px;
    }
}
@media only screen and (min-width: 0px){
    .sliderContainer{
        margin-left: 30px;
        margin-right: 30px;
    }
}
/* className="mx-auto h-96 flex-col items-center justify-center" */



:root {
    --cover-timing: 0.5s; 
    --cover-ease: cubic-bezier(0.66, 0.08, 0.19, 0.97);
    --cover-stagger: 0.15s;
    
    --text-timing: .75s;
    --text-stagger: 0.015s;
    --text-ease: cubic-bezier(0.38, 0.26, 0.05, 1.07);
    
    --title-stagger: 0.05s;
    --highlight: white;
  }
  
  
  .sliderContainer {
    
    position: relative;
    overflow: hidden;
    aspect-ratio: 9/12;
    display: flex;
    flex-direction: column;
    
    border-radius: 16px;
    box-shadow:
      rgba(255,255,255,0.3) 0 5vw 6vw -8vw,
      rgba(255,255,255,0) 0 4.5vw 5vw -6vw,
      rgba(50, 50, 80, 0.5) 0px 4vw 8vw -2vw, 
      rgba(0, 0, 0, 0.8) 0px 4vw 5vw -3vw;
    
    transition: box-shadow 1s var(--cover-ease);
    
    & > * {
      z-index: 2;
    }
    
    & > img {
      z-index: 0;
      transition: all .8s cubic-bezier(0.66, 0.08, 0.19, 0.97);;
    }
    
    &::before,
    &::after {
      content: "";
      width: 100%;
      height: 50%;
      top: 0;
      left: 0;
      background: rgba(0,0,0,0.5);
      position: absolute;
      transform-origin: left;
      transform: scaleX(0);
      transition: all var(--cover-timing) var(--cover-ease);
      z-index: 1;
    }
    
    &::after {
      transition-delay: var(--cover-stagger);
      top: 50%;
    }
    
    &:hover,
    &:focus {
    
      box-shadow: 
        white 0 5vw 6vw -9vw,
        var(--highlight) 0 5.5vw 5vw -7.5vw,
        rgba(50, 50, 80, 0.5) 0px 4vw 8vw -2vw, 
        rgba(0, 0, 0, 0.8) 0px 4vw 5vw -3vw;
     
      &::before,
      &::after {
        transform: scaleX(1);
      }
  
      & .sliderText .char,
      & p .word {
        opacity: 1;
        transform: translateY(0);
        color: inherit;
      }
  
      & .sliderText .char {
        transition-delay: calc(0.1s + var(--char-index) * var(--title-stagger));
      }
      
      & p .word {
        transition-delay: calc(0.1s + var(--word-index) * var(--text-stagger));
      }
      
      & img {
        transform: scale(1.1);
      }
      
    }
    
    .sliderText{
      --highlight: coral;
    }
    
    &:nth-of-type(2) {
      --highlight: #56ffe5;
    }
    
  }
  
  .sliderText {
    position: absolute;
    inset: 20px;
    top: auto;
  }
  
  .sliderText {
    font-size: 30px;
    font-size: clamp(20px, 4vw, 40px);
    font-weight: 800;
    margin-bottom: 0.2em;
  }
  
  p {
    font-size: 12px;
    font-size: clamp(10px, 1.25vw, 14px);
    line-height: 1.4;
    text-align: justify;
    margin-top: 0.2em;
    margin-bottom: 0;
  }
  
  .sliderText .char, 
  p .word {
    color: var(--highlight);
    display: inline-block;
    opacity: 0;
    position: relative;
    transform: translateY(20px);
    transition-property: transform, opacity, color; 
    transition-timing-function: var(--text-ease);
    transition-duration: var(--text-timing), var(--text-timing), calc(var(--text-timing)*2);
  }
  
  /* img {
    position: absolute;
    inset: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 7px;
  } */
  
  
  
  
  
  
  
  
  main {
    
    grid-template-columns: 1fr;
    grid-template-rows: 60px;
    grid-gap: 2em;
    
    @media screen and (min-width: 600px) {
      
      grid-template-columns: 1fr 1fr;
      grid-template-rows: min-content 1fr;
      
    }
    
  }
  
  .sliderContainer {
    

    min-width: 300px;
    /* height: 360px; */
    width: auto;
    

    
  }
  

  
  /* body, main {
    
    display: grid;
    place-items: center;
    
  } */
  
  /* body, html {
    
    color: white;
    background: #333844;
    padding: 0;
    margin: 0;
    min-height: 100vh;
    font-family: "Open Sans", sans-serif;
    
  }
   */
  /* body {
    
    padding: 1em 0 3em;
    min-height: calc(100vh - 4em);
    
  } */
  
  