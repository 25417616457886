@import url('https://fonts.cdnfonts.com/css/euclid-circular-a');
.gradient{
    padding: 68px 50px;
    border-radius: 32px;
    background: linear-gradient(257deg, #151A30 0%, #211D4D 28%, #271630 70%, #0F1836 100%), linear-gradient(0deg, rgba(21, 26, 48, 0.40) 0%, rgba(21, 26, 48, 0.40) 100%);
}

.ourValuesImage{
    width: 128px;
    height: 128px;
    margin: 0;
    padding: 0;
}

.container{
    display: flex;
    align-items: center;
    /* margin-bottom: 80px; */
}
.content{
    /* margin-left: 40px; */
    width: 80%;
}
.title{
    color: #EDF1F7;
font-size: 28px;
font-weight: 500;
font-family: 'Euclid Circular A', sans-serif;
border-bottom: 1px solid #e32682;
padding-bottom: 6px;
/* width: 100%; */
width: 80%;


}
.text{
    color: #E4E9F2;
font-size: 16px;
font-family: 'Euclid Circular A', sans-serif;
font-weight: 400;
line-height: 22px;
width: 80%;

margin-top: 12px;
}

@media only screen and (max-width: 500px){
 
.ourValuesImage{
    width: 100px;
    height: 100px;
    margin: 0;
    padding: 0;
}
    .text{
    font-size: 14px;
    line-height: 20px;
    margin-top: 2px;
    }
    .title{
    font-size: 16px;
    font-weight: 500;
    font-family: 'Euclid Circular A', sans-serif;
    border-bottom: 1px solid #e32682;
    padding-bottom: 0px;
    
    }
}


.web{
    display: flex;
    font-family: 'Euclid Circular A', sans-serif;
    /* background-color: #151A30; */
    color: #fff;
    /* border: 1px solid #e32682; */
    font-size: 32px;
    font-weight: 500;
    /* border-radius: 30px; */
    /* padding: 8px 32px; */
    display: inline-block;
    margin-bottom: 32px;
}
.mobile{
    display: flex;
    font-family: 'Euclid Circular A', sans-serif;
    /* background-color: #151A30; */
    color: #fff;
    /* border: 1px solid #e32682; */
    font-size: 24px;
    font-weight: 400;
    /* border-radius: 30px; */
    /* padding: 8px 32px; */
    margin-bottom: 30px;
    display: none;
}
@media only screen and (max-width: 1080px){
.web{
    display: none;
}
.mobile{
    display: block;
}
.gradient{
        padding: 36px 28px;
}
}
@media only screen and (max-width: 1279.5px){
    .web{
        display: none;
    }
    .mobile{
        display: block;
    }
    .gradient{
            /* padding: 36px 32px; */
    }
    .content{

        width: 100%;
    }

    .title{

        width: 100%;
    }
    .text{

        width: 100%;
    }

    }
    



@media only screen and (max-width: 639.5px){
    .gradient{
        border-radius: 0;
            padding: 25px 0px;
    }

    }