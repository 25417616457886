@import url('https://fonts.cdnfonts.com/css/euclid-circular-a');
.sidebarCardLink{
    margin-bottom: 16px;
    height: 104px;

}
.card {
    cursor: pointer;
    border: 0;
    box-shadow: 0;
    transition: all 0.5s;
    height: 104px;
    display: flex;
    /* background-color: #101426; */

}

.image {
    transition: all 0.5s;
    border-radius: 16px;
    filter: blur(0px);
    height: 104px !important;
    width: 104px !important;

}
.imageContainer{
    /* width: 335.13px; */
    /* height: 321.09px; */
    /* background-color: #E32682; */
    display: flex;
    justify-content: center;
    align-items: center;
    max-height: 370px;
    position: relative;
    z-index: 555;
    /* background-color: #F7F9FC; */

}

.content {
    padding: 16px 24px;
    border: 1px solid #e4e9f2;
    border-radius: 16px;
    transition: all 0.5s;
    height: 103.6px;
    width: 406px;
}



.title {
    color: #192038;
    font-size: 24px;
    font-family: 'Euclid Circular A', sans-serif;
    font-weight: 700;
    line-height: 32px;
    margin: 0 0 12px 0;
    transition: all 0.5s;
    white-space: nowrap;
}

.date {
    font-family: 'Euclid Circular A', sans-serif;
    color: #2E3A59;
    font-size: 16px;
    font-weight: 400;
    transition: all 0.5s;
    white-space: nowrap;
    line-height: 24px;
}
.hall {
    font-family: 'Euclid Circular A', sans-serif;
    color: #2E3A59;
    font-size: 16px;
    font-weight: 400;
    transition: all 0.5s;
    white-space: nowrap;
    line-height: 24px;
}

.price {
    color: #DE3487;
    font-size: 20px;
    font-family: 'Euclid Circular A', sans-serif;
    font-weight: 500;
    line-height: 28px;
    white-space: nowrap;
}
.ellipse{
    margin: 0 8px;
    transition: all 0.5s;
    width: 6px;
}


.card:hover .content {
    transition: all 0.5s;
    background-color: #e4e9f2;
}


.card:hover .image {
    /* backdrop-filter: blur(10px); */
    /* filter: blur(4px); */
    -webkit-filter: brightness(60%);
    -webkit-transition: all 1s ease;
    -moz-transition: all 1s ease;
    -o-transition: all 1s ease;
    -ms-transition: all 1s ease;
    transition: all 1s ease;
}



@media only screen and (max-width: 574px){
    .card{
        height: 68px;

    }
    .sidebarCardLink{
        height: 68px;

    }
    .image {
        border-radius: 8px;
        height: 68px !important;
        width: 68px !important;
    
    }
    .content {
        padding: 10px 12px !important; 
        border: 1px solid #e4e9f2;
        border-radius: 16px;
        transition: all 0.5s;
        height: 68px;
        width: 300px;
    }
    .title {
        font-size: 16px;
        line-height: 24px;
        margin: 0 0 8px 0;
    }
    .date {
        font-size: 12px;
        font-weight: 500;
        line-height: 16px;
    }
    .hall {
        font-size: 12px;
        font-weight: 400;
        line-height: 16px;
    }
    
    .price {
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
    }
    
   
}


@media only screen and (max-width: 400px){
    .content {
        width: 240px;
    }
    
}
    
@media only screen and (max-width: 1002px){
   
.card {
    /* width: 350px; */

}
}
/* @media only screen and (max-width: 502px){
   
    .card {
        width: 240px;
    
    }
    
    .image {
        transition: all 0.5s;
        border-radius: 24px;
        filter: blur(0px);
        width: 240px;
        height: 240px;
    }
    } */
    /* @media only screen and (max-width: 640px){
 
 
        
        
        .image {
            transition: all 0.5s;
            border-radius: 24px;
            filter: blur(0px);
            width: 320px;
            height: 320px;
        }
    } */
    
@media only screen and (max-width: 502px){
    .ellipse,
    .date{
        display: none;
    }

    
}