@import url('https://fonts.cdnfonts.com/css/euclid-circular-a');

.card {
    cursor: pointer;
    border: 0;
    box-shadow: 0;
    transition: all 0.5s;
    margin: 0 0 40px 0;
    width: 375px;
    border-radius: 50px;
    height: 321.54px;
    background-color: #101426;

}
.image {
    transition: all 0.5s;
    border-radius: 24px;
    filter: blur(0px);
    /* height: 375px; */

}
.imageContainer{
    /* width: 335.13px; */
    /* height: 321.09px; */
    /* background-color: #E32682; */
    display: flex;
    justify-content: center;
    align-items: center;
    max-height: 574px;
    position: relative;
    z-index: 555;
    /* background-color: #F7F9FC; */

}

.content {
    padding: 10px 24px 12px 24px;
    border: 1px solid #192038;
    border-radius: 0 0 24px 24px;
    background-color: #101426;
    transition: all 0.5s;
    height: 91.6px;
    position: relative;
    top: -72px;
    /* background-color: #F7F9FC; */
    display: flex;
    align-items: end;

}

.ticket{
    padding: 10px 20px;
    color: #fff;
font-size: 20px;
font-family: Euclid Circular A;
font-weight: 500;
text-align: center;
/* line-height: 32px; */
/* min-width: 198px; */
/* max-width: 198px; */
font-family: 'Euclid Circular A', sans-serif;
border-radius: 8px;
height: min-content;
position: absolute;
background-color: #E32682;
/* top: 190.545px; */
/* left: 120px; */
z-index: 5;
visibility: hidden;
transition: all 0.1s;
filter: blur(10px);

}

.titleDateContainer{
    padding: 10px 24px;

    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    top: -59px;
    z-index: 999;

}

.title {
    color: #151A30;
    font-size: 24px;
    font-family: 'Euclid Circular A', sans-serif;
    font-weight: 700;
    line-height: 32px;
    margin: 0 0  0;
    transition: all 0.5s;
    color: white;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
}

.date {
    font-family: 'Euclid Circular A', sans-serif;
    color: white;
    font-size: 16px;
    font-family: Euclid Circular A;
    font-weight: 400;
    transition: all 0.5s;
    
    line-height: 24px;
}
.desc{
    color: #edf1f7;
    transition: all 0.5s;
    font-size: 16px;
    font-family: 'Euclid Circular A', sans-serif;
    font-weight: 400;
    line-height: 24px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    /* word-wrap: break-word; */
    -webkit-line-clamp: 2;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    line-height: 1.5em; /* Sets line height to 1.5 times text size */
    height: 3em; /* Sets the div height to 2x line-height (3 times text size) */
    width: 100%; /* Use whatever width you want */
    white-space: normal; /* Wrap lines of text */
    overflow: hidden; /* Hide text that goes beyond the boundaries of the div */
    text-overflow: ellipsis; /* Ellipses (cross-browser) */
    -o-text-overflow: ellipsis; /* Ellipses (cross-browser) */
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2; /* number of lines to show */
    line-height: 24px;        /* fallback */
    max-height: 48px;       /* fallback */
    /* background-color: #F7F9FC; */

}
.hall {
    font-family: 'Euclid Circular A', sans-serif;
    color: #192038;
    font-size: 16px;
    font-family: Euclid Circular A;
    font-weight: 400;
    transition: all 0.5s;

    line-height: 24px;
}

.price {
    color: #E32682;
    font-size: 20px;
    font-family: 'Euclid Circular A', sans-serif;
    font-weight: 500;
    line-height: 28px;
}
.ellipse{
    margin: 0 4px;
    transition: all 0.5s;
    filter: invert(100%) sepia(0%) saturate(2%) hue-rotate(266deg) brightness(104%) contrast(101%);

}


.card:hover .content {
    transition: all 0.5s;
    background-color: #f7f9fc;
}


.card:hover .date {
    color: #151A30;
}
.card:hover .hall {
    color: #151A30;
}
.card:hover .desc{
    color: #151A30;

}

.card:hover .image {
    /* backdrop-filter: blur(10px); */
    /* filter: blur(4px); */
    -webkit-filter: brightness(60%);
    -webkit-transition: all 1s ease;
    -moz-transition: all 1s ease;
    -o-transition: all 1s ease;
    -ms-transition: all 1s ease;
    transition: all 1s ease;

}



.card:hover .ticket {
visibility: visible;
transition: all 0.1s;
filter: blur(0px);

}





@media only screen and (max-width: 1435px){
    .ellipse,
    .date{
        display: none;
    }
 
    
}

@media only screen and (max-width: 1002px){
   
    .card {
        width: 350px;
    
    }
    .image {
        transition: all 0.5s;
        width: 350px;
    }
}    

@media only screen and (max-width: 690px){
    .ellipse,
    .date{
        display: none;
    }
    
}

@media only screen and (max-width: 640px){
    .card {
        /* width: 100%; */
    
    }
    .image {
        /* height: 223.89px !important; */
        /* width: 100%; */

    }
}
/* 
@media only screen and (max-width: 457px){
   
    .title{
        font-size: 22px;
    }
    .image {
    }

}
@media only screen and (max-width: 426px){
    .title{
        font-size: 20px;
    }

    
}
@media only screen and (max-width: 394px){
    .title{
        font-size: 18px;
    }
    
} */